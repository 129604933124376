'use client';

import React, { useState, useCallback, useEffect, ReactNode, CSSProperties } from 'react';
import '@/components/styles/swiper.scss';

import useEmblaCarousel from 'embla-carousel-react';

import {
  EmblaCarouselType,
  EmblaOptionsType,
} from 'embla-carousel';

import tw from 'tailwind-styled-components';

export const Slide = tw.div`
embla__slide
`;

interface Props {
  children: ReactNode;
}

const options: EmblaOptionsType = {
  containScroll: 'trimSnaps',
  dragFree: true,
};

export function Swiper({ children }: Props) {
  // @ts-ignore
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const next = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
  }, [emblaApi]);

  const prev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <>
      <div className='relative w-full'>
        <div className='embla' ref={emblaRef}>
          <div className='embla__container'>
            {children}
          </div>
        </div>

        <div
          className='flex justify-center relative top-0 right-0 mt-4 md:mt-0 embla__buttons'>
          <button
            disabled={prevBtnDisabled}
            onClick={prev}
            className={`${prevBtnDisabled ? 'bg-[#80808029] focus:outline-none' : 'white-button'}  mx-1 circle-button-shadow py-2 px-4 rounded-full`}>
            <span className='text-[20px] mdi mdi-chevron-left' />
          </button>
          <button
            disabled={nextBtnDisabled}
            onClick={next}
            className={`${nextBtnDisabled ? 'bg-[#80808029] focus:outline-none' : 'blue-button'}  mx-1 circle-button-shadow py-2 px-4 rounded-full`}>
            <span className='text-[20px] mdi mdi-chevron-right' />
          </button>
        </div>
      </div>
    </>
  );
}